import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import "./Home.css";
import MailIcon from "@material-ui/icons/Mail";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import EventNoteIcon from "@material-ui/icons/EventNote";
import CallIcon from "@material-ui/icons/Call";
import Type from "./Type";
import { Link } from "react-scroll";
function Home() {
  const [animate, setAnimate] = useState(false);
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // const scrollTop = document.documentElement.scrollTop;
      // const targetScroll =
      //   document.getElementById("home_triggerPoint").offsetTop; // Adjust 'triggerPoint' to the ID of the element you want to trigger the animation
      // console.log(targetScroll);
      // if (scrollTop > targetScroll - 300 && !triggered) {
      // Set triggered to true to ensure animation triggers only once
      // }
    };
    setTimeout(() => {
      setAnimate(true);
      setTriggered(true);
    }, 300);
    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  return (
    <Element name="home">
      <div className="container-fluid home" id="home_triggerPoint">
        <div className="row ">
          <div
            className={`col-sm-12 col-md-6 col-lg-6 home-left-container ${
              animate && "home_ani_left"
            }`}
          >
            <div>
              <h2>
                <span className="hi"> Hi, </span>
                <span
                  style={{
                    fontSize: "25px",
                  }}
                >
                  I'm
                </span>
              </h2>
              <h2>
                <span className="hi">Sivanathan</span>
              </h2>
              <h5 className="company_name">
                {/* <span className="hi"> */}
                Software developer @ SFLHUB Technologies
                {/* </span> */}
              </h5>
              <h4
                className="type"
                style={{
                  color: "red",

                  minWidth: "320px",
                }}
              >
                <Type />
              </h4>
              <div className="home-icon">
                <span
                  onClick={() =>
                    window.open("https://github.com/Sivav15", "_blank")
                  }
                >
                  <GitHubIcon fontSize="medium" />
                </span>
                <span
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/in/sivanathan-v-092484218/",
                      "_blank"
                    )
                  }
                >
                  <LinkedInIcon fontSize="medium" />
                </span>
                <span
                  onClick={() =>
                    window.open("mailto:sivanathanv1999@gmail.com", "_blank")
                  }
                >
                  <MailIcon fontSize="medium" />
                </span>
                <span
                  onClick={() => window.open("tel://+919566940955", "_blank")}
                >
                  <CallIcon fontSize="small" />
                </span>
              </div>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={350}
                duration={250}
              >
                <button type="button" className="home-button">
                  Hire Me <TouchAppIcon />
                </button>
              </Link>
              <Link
                to="project"
                spy={true}
                smooth={true}
                offset={-90}
                duration={250}
              >
                <button type="button" className="home-button ms-3">
                  Project <EventNoteIcon />
                </button>
              </Link>
            </div>
          </div>
          <div
            className={`col-sm-12 col-md-6 col-lg-6 home-right-container ${
              animate && "home_ani_right"
            }`}
          >
            <img src="./asset/programmer.svg" alt="heroimage2" />
          </div>
        </div>
      </div>
    </Element>
  );
}

export default Home;
