import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Element } from "react-scroll";
import "./About.css";

function About() {
  const paragraphs = [
    "I have **1 years of working experience** in software industry and currently working as a **Full-Stack Developer @ SFLhub Technologies, Chennai**.",
    "I am an innovative and dedicated **hard working full stack developer** with **hands-on experience in developing web and mobile applications**.",
    "I have passion for **developing scalable web application** and working across the full stack.",
    "**I spend most days learning** and **building up**, either start it from **scratch and adding some customized features and functionality**.",
  ];

  const [animate, setAnimate] = useState(false);
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const targetScroll =
        document.getElementById("about_triggerPoint").offsetTop;
      if (scrollTop > targetScroll - 500 && !triggered) {
        setAnimate(true);
        setTriggered(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [triggered]);

  return (
    <Element id="about">
      <div className="about-main" id="about_triggerPoint">
        <h2 className={`${animate && "about-title"}`}>
          Know Who <span className="about-highlight">I'M</span>
        </h2>
        <div className="about">
          <div className="a_contains mx-auto">
            <div className={`about-left-contain ${animate && "about-img"}`}>
              <img
                src="https://www.roessner.tech/static/media/about.d8fe852f6a9badcd8616.png"
                alt="about"
              />
            </div>
            <div className={`about-right-contain ${animate && "about-text"}`}>
              <div className="paragraph">
                {paragraphs.map((paragraph, index) => (
                  <p key={index} style={{ textIndent: "35px" }}>
                    <ReactMarkdown
                      components={{
                        strong: ({ node, ...props }) => (
                          <span className="about-highlight" {...props} />
                        ),
                      }}
                    >
                      {paragraph}
                    </ReactMarkdown>
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default About;
