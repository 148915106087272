import React, { useState } from "react";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
import Project from "./components/project/Project";
import Skills from "./components/skill/Skills";
import Skills_1 from "./components/new-skils/Skills";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Experience from "./components/experience/Experience";

function App() {
  const [open, setOpen] = useState(false);

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div onTouchMove={() => setOpen(false)}>
          <Navbar open={open} setOpen={setOpen} />
          <Home />
          <About />
          {/* <Skills /> */}
          <Skills_1 />
          <Project />
          {/* <Achievement /> */}
          <Contact />
          <Footer />
        </div>
      ),
    },
    {
      path: "experience",
      element: (
        <div onTouchMove={() => setOpen(false)}>
          <Navbar open={open} setOpen={setOpen} />
          <Experience />
          <Footer />
        </div>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
