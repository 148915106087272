import React, { useEffect, useState } from 'react'
import { Element } from 'react-scroll'
import skill_img from '../../assets/skillsLogo.svg'
import './skills.css'
const Skills = () => {
    const [isActive, setActive] = useState({
        toggle: false,
        id: null
    })

    const [animate, setAnimate] = useState(false);
    const [triggered, setTriggered] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = document.documentElement.scrollTop;
            const targetScroll = document.getElementById('triggerPoint').offsetTop; // Adjust 'triggerPoint' to the ID of the element you want to trigger the animation
            console.log(targetScroll);
            if (scrollTop > targetScroll - 500 && !triggered) {
                setAnimate(true);
                setTriggered(true); // Set triggered to true to ensure animation triggers only once
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggered]);

    const skills = [{
        name: "React",
        link: "./asset/react.svg",
        color: "",
        class: 'reactLogoRotating'
    },
    {
        name: "React-Native",
        link: "./asset/react.svg",
        color: "",
        class: 'reactLogoRotating'
    },
    {
        name: "NodeJS",
        link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
        color: "",
    },

    {
        name: "Express",
        link: "./asset/expressjs.png",
        color: "",
    },

    {
        name: "Redux",
        link: "./asset/redux.svg",
        color: "",
    },
    {
        name: "Javascript",
        link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
        color: "",
    },
    {
        name: "Typescript",
        link: "./asset/ts.svg",
        color: "",
    },
    {
        name: "Python",
        link: "./asset/python.svg",
        color: "",
    },
    {
        name: "HTML5",
        link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
        color: "",
    },
    {
        name: "CSS3",
        //  link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg",
        link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
        color: "",
    },
    {
        name: "AWS",
        link: "./asset/aws.png",
        color: "",
    },
    {
        name: "MySQl",
        link: "./asset/mysql.png",
        color: "",
    },
    {
        name: "MSSQL",
        link: "./asset/mssql.svg",
        color: "white",
    },
    {
        name: "Mongo DB",
        link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg",
        // link : "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg",
        color: "",
    },

    {
        name: "Socket.IO",
        link: "./asset/socketio.svg",
        color: "",
        mofify: true,
    },
    {
        name: "Tailwind CSS",
        link: "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg",
        color: "",
    },
    {
        name: "GitHub",
        link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg",
        color: "",
    },
    {
        name: "Postman",
        link: "https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg",
        color: "",
    },
    ];
    const tools = [

        {
            name: "VsCode",
            link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg",
            color: "",
        },
        {
            name: "Heroku",
            link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain.svg",
            color: "",
        },
        {
            name: "GitHub",
            link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg",
            color: "",
        },
        {
            name: "Netlify",
            link: "./asset/netlify.svg",
            color: "",
        },
        {
            name: "Vercel",
            link: "./asset/vercel.svg",
            color: "",
        },]
    return (
        <Element name="skills" >
            <div className='skills-main' id="triggerPoint">
                <h2 className={`${animate && 'ani-text'}`}>My <span className='skills-highlight'>Tech Stack</span></h2>
                <div className="skills-container">
                    <div className="image-container">
                        <img src={skill_img} alt='skills'
                            className={`skill-image ${animate && 'ani-image'}`}
                        />
                    </div>
                    <div className="tech-stack-container">
                        <div className="skills-wrapper">
                            {skills.map((item, index) => (
                                <div key={index} className={`skill-item ${animate && 'ani-item'}`}>
                                    <p>{item.name}</p>
                                    <img
                                        style={{
                                            backgroundColor: item.color
                                        }}
                                        src={item.link}
                                        alt=""
                                        className={`skill-icon ${isActive.toggle && isActive.id === index ? 'skill_img_animation_in' : 'skill_img_animation_out'}`}

                                        onMouseEnter={() => {
                                            setActive({
                                                toggle: true,
                                                id: index
                                            });

                                        }}
                                        onMouseOut={() => {
                                            setActive({
                                                toggle: false,
                                                id: null
                                            });
                                        }}
                                    />

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </Element>
    )
}

export default Skills